<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left--white">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					{{t('10099')}}
				</span>
			</p>
			<router-link to="/layers/terms/point" class="nav__btn">
				<i class="icon icon-point--white">{{t('10099')}}</i>
			</router-link>
		</div>
	</nav>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<span v-if="isLogin">
								<strong>{{userData.nick_nm}}</strong>{{t('10390')}}

							</span>
						</div>
					</div>
					<div class="mypage__my-point">
						<small>
							{{t('10391')}}
						</small>
						<strong>{{currentPoint}}</strong>
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<div class="mypage__inner">
							<!-- 마이페이지 포인트 상세 -->
							<div class="mypage__pointdetail">
								<!-- 포인트 탭 -->
								<div class="tab">
									<a @click="onClickUpdateFilterType(undefined)" :class="{'active' : filterType === undefined}">{{t('10389')}}</a>
									<a @click="onClickUpdateFilterType('적립')" :class="{'active' : filterType === '적립'}">{{t('10432')}}</a>
									<a @click="onClickUpdateFilterType('사용')" :class="{'active' : filterType === '사용'}">사용내역</a><!--재번역 체크-->
								</div>
								<!-- [D] 활성화 시 active 클래스 추가 -->
								<div class="picker">
									<button type="button" @click="onClickLoadPoint(180)" :class="{'active' : currentPointDate === 180}">{{t('10395')}}</button>
									<button type="button" @click="onClickLoadPoint(90)" :class="{'active' : currentPointDate === 90}">{{t('10394')}}</button>
									<button type="button" @click="onClickLoadPoint(30)" :class="{'active' : currentPointDate === 30}">{{t('10393')}}</button>
									<button type="button" @click="onClickLoadPoint(7)" :class="{'active' : currentPointDate === 7}">{{t('10392')}}</button>
								</div>
								<ul class="info info--small" v-show="!isNoPoint">
									<li class="subject">
										<h4 class="info__subject">
											{{t('10409')}}
										</h4>
										<h4 class="info__subject">
											{{t('10099')}}
										</h4>
									</li>
									<li v-for="item in pointList" :key="item.index">
										<h4 class="info__title">
											{{item.date}}
										</h4>
										<div class="info__wrap">
											<div class="info__text">
												{{item.desc}}
												<strong :class="item.color">{{item.amount}}</strong>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="textbox textbox--margin" v-show="isNoPoint">
					<div class="textbox__icon">
						<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
					</div>
					<h3 class="textbox__title">{{t('10410')}}</h3>
					<p class="textbox__description">
						{{t('10397')}}
					</p>
					<div class="textbox__notice">
						{{t('10398')}} <strong  class="skyblue" v-if="mmy0101.length > 0">{{mmy0101[0].recomm_cd}}</strong>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref} from "vue"
import { useRouter } from 'vue-router';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
dayjs.locale("ko");
export default {
	setup: function() {
		const store = useStore();
		const router = useRouter();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy010401 = computed(() => store.state.mmy01.mmy010401);
		const mmy0101 = computed(() => store.state.mmy01.mmy0101);

		const currentPointDate = ref(180);
		const filterType = ref(undefined);
		const { t }= useI18n() //번역필수 모듈
		const currentPoint = computed(() =>
			isLogin.value && mmy010401?.value[0]?.MEM_POINT !== undefined
				? parseInt(mmy010401?.value[0]?.MEM_POINT).toLocaleString("en-US") + "P"
				: "-"
		);
		const isNoPoint = computed(() =>
			pointList.value.length > 0 
				? pointList.value[0].date === "Invalid Date" 
					? true
					: false
				: true 
		);
		const pointList = computed(() => mmy010401.value.filter(x =>filterType.value === undefined ? true : x.GUBUN === filterType.value).map((x, i)=>{
			//console.log(x.DESC);
			return {
				index: i,
				date: dayjs(x.YMD).format("YYYY.MM.DD"),
				desc: x.DESC,
				color: (x.GUBUN === "적립"? Math.max(x.USE_AMT, x.SAVE_AMT) > 0 ? 'black' : "red" : "red"),
				amount: (x.GUBUN === "적립"? Math.max(x.USE_AMT, x.SAVE_AMT) > 0 ? "+" : '' : "-") + Math.max(x.USE_AMT, x.SAVE_AMT).toLocaleString("en-US"),
			}
		}));

		onMounted(()=>{
			if(isLogin.value) {
				loadPointList(dayjs().subtract(180, "day").format("YYYYMMDD"), dayjs().format("YYYYMMDD"));

				store.dispatch("mmy01/fetchMmy0101", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				});
			}
		});

		const goBack = () => {
			router.go(-1);
		};

		const onClickLoadPoint = (date) => { 
			if(currentPointDate.value !== date){
				loadPointList(dayjs().subtract(date, "day").format("YYYYMMDD"), dayjs().format("YYYYMMDD"));
				currentPointDate.value = date
			}
		};

		const onClickUpdateFilterType = (type) => {
			filterType.value = type;
		}

		const loadPointList = (startDate ,endDate) => {
			store.dispatch("mmy01/fetchMmy010401", {
				proc_cd: "01",
				mem_id: userData.value.mem_token,
				sdate: startDate,
				edate: endDate,
			});
		};

		return {
			userData,
			isLogin,
			goBack,
			currentPoint,
			pointList,
			isNoPoint,
			onClickLoadPoint,
			currentPointDate,
			mmy0101,
			filterType,
			onClickUpdateFilterType,
			t,
			i18n
		}
	}
}
</script>